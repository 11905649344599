import Vue from "vue";

/*TITLE*/
document.title = "Pérez Galdós | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Pérez Galdós";
Vue.prototype.$subtitle = "";

/*INTRO*/
Vue.prototype.$promoter = "Pérez Galdós";
Vue.prototype.$introSubtitle = "";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "perez-galdos_salon_ac6-yukon--20230126020147.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "perez-galdos_banyo1_ac6-yukon-vela-smoke--20230126020126.jpg";
Vue.prototype.$image_bath2 = "perez-galdos_banyo2_ac6-yukon-vela-smoke--20230126020121.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "perez-galdos_salon_ac6-yukon--20230126020147.jpg",
  },
  {
    src: "perez-galdos_salon_ac6-natural-grande--20230126020155.jpg",
  },
  {
    src: "perez-galdos_salon_ac6-mystic--20230126020102.jpg",
  },
  {
    src: "perez-galdos_salon_ac6-michigan-clear--20230126020108.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "perez-galdos_banyo1_ac6-yukon-vela-smoke--20230126020126.jpg",
  },
  {
    src: "perez-galdos_banyo1_ac6-michigan-grande-vela-grey--20230126020139.jpg",
  },
  {
    src: "perez-galdos_banyo1_ac6-mystic-vela-black--20230126020150.jpg",
  },
  {
    src: "perez-galdos_banyo1_ac6-michigan-clear-vela-natural--20230126020109.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "perez-galdos_banyo2_ac6-yukon-vela-smoke--20230126020121.jpg",
  },
  {
    src: "perez-galdos_banyo1_ac6-michigan-grande-vela-grey--20230126020134.jpg",
  },
  {
    src: "perez-galdos_banyo1_ac6-mystic-vela-black--20230126020144.jpg",
  },
  {
    src: "perez-galdos_banyo1_ac6-michigan-clear-vela-natural--20230126020152.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "6";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=palma%20de%20mallorca",
    text: "Palma de Mallorca",
  },
];

Vue.prototype.$showroom = [
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:666800373",
    text: "666800373",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@gestecmallorca.com",
    text: "info@gestecmallorca.com",
  },
];
